<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" label-position="left">
      <el-form-item>
        <div slot="label">编码<font color="red">*</font></div>
        <el-input v-model="form.code" placeholder="编码" />
      </el-form-item>
      <el-form-item>
        <div slot="label">名称<font color="red">*</font></div>
        <el-input v-model="form.name" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item>
        <div slot="label">值<font color="red">*</font></div>
        <el-input v-model="form.value" placeholder="请输入值" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div style="text-align:center;">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button type="danger" class="quxiao_btn" @click="closePage()">取消</el-button>
      </div>
    </span>
  </div>
</template>

<script>
import { saveData } from '@/api/maSysValue'

import { Message } from 'element-ui'
export default {
  inject: ['getList'],
  data() {
    return {
      form: {}
    }
  },
  created() {},
  methods: {
    checkData() {
      var flag = true
      if (!this.form.code || !this.form.name) {
        flag = false
        this.$message.error('编码或名称不能为空')
      } else if (!this.form.value) {
        flag = false
        this.$message.error('值不能为空')
      }
      return flag
    },
    async onSave() {
      if (this.checkData()) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        saveData(this.form).then(response => {
          Message({
            message: '新增成功',
            type: 'success',
            duration: 5 * 1000
          })

          this.$emit('update:visible', false)
          loading.close()
          this.getList()
        }).catch(response => {
          loading.close()
          this.getList()
        })
      }
    },
    closePage() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style>

</style>
