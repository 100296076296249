<template>
  <div class="contain">
    <div id="app-contain">
        <div class="filter-container" id="filter-container">
      <el-input v-model="queryPage.code" class="filter-item" placeholder="编码" style="width: 150px" clearable />
      <el-input v-model="queryPage.name" class="filter-item" placeholder="名称" style="width: 150px" clearable />

      <el-button class="filter-item" icon="el-icon-search" type="primary" @click="getList()">查询</el-button>
    <el-button type="primary" @click="handleAdd" style="position:absolute;right:0">新增</el-button>
    </div>


    <el-table v-loading="listLoading" :data="tableData" :height="tabheight" style="width: 100%; overflow:auto" border @selection-change="selsChange">
      <el-table-column label="序号" type="index" :index="indexMethod" align="center" header-align="center" width="80" />
      <el-table-column header-align="center" align="left" fixed="right" width="150px" label="操作">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleEdit(scope)">编辑</el-button>
          <el-button v-if="scope.row.state==='1'" type="danger" size="small" @click="handleChange(scope, '2', '禁用')">禁用</el-button>
          <el-button v-if="scope.row.state==='2'" type="primary" size="small" @click="handleChange(scope, '1', '启用')">启用</el-button>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="left" label="编码">
        <template slot-scope="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="left" label="名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="left" label="值" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.value }}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="left" label="状态" width="120">
        <template slot-scope="scope">
          <span>{{ getDicName(scope.row.state,'YW_BASE_STATUS') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人" header-align="center" align="left" width="150">
        <template slot-scope="scope">
          {{ scope.row.createUserName }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" header-align="center" align="right" width="200">
        <template slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column>
    </el-table>

    <Pagination id="pagination" v-show="total>-1" :total="total" :page.sync="queryPage.page" :limit.sync="queryPage.limit" @pagination="getList" />

    <el-dialog :close-on-click-modal="false" :visible.sync="menuAddVisible" append-to-body title="新增" width="600px">
      <menu-add v-if="menuAddVisible" ref="menuAdd" :visible.sync="menuAddVisible" />
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :visible.sync="menuEditVisible"  append-to-body title="编辑" width="600px">
      <menu-edit v-if="menuEditVisible" ref="menuEdit" :proid="code" :visible.sync="menuEditVisible" />
    </el-dialog>
    </div>
  </div>
</template>
<script>
import { getPage, updateState } from '@/api/maSysValue'
import { getDictListByCode } from '@/api/dict'
import { Message, MessageBox } from 'element-ui'
import { tableHeight2 } from '@/utils/tableHeight'
import Pagination from '@/components/Pagination'

import menuAdd from '../maSysValue/add' // src\views\super\maSys\maDict\maSysValue\add.vue
import menuEdit from '../maSysValue/edit' // eslint-disable-line no-unused-vars

export default {
  components: { Pagination, menuAdd, menuEdit },
  mixins: [tableHeight2],
  provide() {
    return {
      getList: this.getList
    }
  },
  data() {
    return {
      tabheight:0,
      content: null,
      tooltip: true,
      sels: [],
      fenleiList: '',
      id: '',
      code: '',
      listLoading: false,
      menuAddVisible: false,
      menuEditVisible: false,
      menuViewVisible: false,
      tableData: {},
      total: 0,
      zhuangtai: null,
      queryPage: {
        page: 1,
        limit: 10,
        code: null,
        name: null,
        hy1: null,
        type: null,
        companyId: null
      },
      obj: []
    }
  },

  mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination
        })()
      }
    })
  },
  created() {
    this.getDictEntry('YW_BASE_STATUS')
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      this.tableData = []
      getPage(this.queryPage).then(response => {
        this.tableData = response.data.records
        this.total = parseInt(response.data.total)

        this.listLoading = false
      }).catch(response => {
        this.listLoading = false
      })
    },
    indexMethod(index) {
      return (index + 1) + (this.queryPage.page - 1) * this.queryPage.limit
    },
    handleAdd() {
      this.menuAddVisible = true
      this.$nextTick(() => {
        this.$refs.menuAdd
      })
    },
    handleEdit(scope) {
      this.menuEditVisible = true
      this.code = scope.row.id
      this.$nextTick(() => {
        this.$refs.menuEdit
        this.$refs.menuEdit.getDetailed()
      })
    },
    handleView(scope) {
      this.menuViewVisible = true
      this.code = scope.row.id
      this.$nextTick(() => {
        this.$refs.menuView
        this.$refs.menuView.getDetailed()
      })
    },
    selsChange(sels) {
      this.sels = sels
    },
    getDictEntry(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_BASE_STATUS') {
          this.fenleiList = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_BASE_STATUS') {
        dict = this.fenleiList
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
    handleChange(scope, flag, type) {
      MessageBox.confirm('确认' + type, '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: type + '中...',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.1)'
        })

        updateState({ code: scope.row.id, state: flag }).then(response => {
          Message({
            message: type + '成功',
            type: 'success',
            duration: 5 * 1000
          })
          // 重新加载表格
          this.getList()
          loading.close()
        }).catch(response => {
          this.getList()
          loading.close()
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.contain{
    height:calc(100% - 20px);
    width:calc(100% - 40px);
    padding:10px 20px;
}
#app-contain{
    height:100%;
    width:100%;
}
.filter-container {
  position:relative;
  padding-bottom:10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
}
.pagination-container{
    margin-top:0 !important;
    padding: 10px 16px !important;
}

</style>
