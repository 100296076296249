import request from '@/utils/request'

export function getPage(data) {
  return request({
    url: '/maSysValue/getPage',
    method: 'post',
    data
  })
}

export function saveData(data) {
  return request({
    url: '/maSysValue/save',
    method: 'post',
    data
  })
}

export function editData(data) {
  return request({
    url: '/maSysValue/edit',
    method: 'post',
    data
  })
}

export function updateState(data) {
  return request({
    url: '/maSysValue/remove',
    method: 'post',
    params: data
  })
}

export function getInfo(data) {
  return request({
    url: '/maSysValue/getInfo',
    method: 'post',
    params: data
  })
}
