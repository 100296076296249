<template>
  <div style="width:100%; margin:0 auto;">
    <el-form :model="form" label-width="80px" label-position="left">
      <el-form-item>
        <div slot="label">字典代码<font color="red">*</font></div>
        <el-input v-model="form.code" placeholder="字典代码" />
      </el-form-item>
      <el-form-item>
        <div slot="label">字典名称<font color="red">*</font></div>
        <el-input v-model="form.name" placeholder="字典名称" />
      </el-form-item>
      <el-form-item>
        <div slot="label">排序号<font color="red">*</font></div>
        <el-input-number v-model="form.sortNum" :min="1" style="width:100%;" controls-position="right" />
      </el-form-item>
    </el-form>
      
    <div style="margin-top:10px;text-align:center;">
      <el-button type="primary" @click="save()">保 存</el-button>
      <el-button type="danger" @click="closePage()">取消</el-button>
    </div>
  </div>
</template>

<script>
import { addDict } from '@/api/dict'

import { Message } from 'element-ui'

export default {
  inject: ['getList'],
  props: {
    proid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {}
    }
  },
  created() {
  },
  methods: {
    save() { // 新增
      if (!this.form.name) {
        this.$message({
          type: 'error',
          message: '字典名称不能为空'
        })
        return
      } else if (!this.form.code) {
        this.$message({
          type: 'error',
          message: '字典代码不能为空'
        })
        return
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(255,255,255,0.1)'
        })
        
        addDict(this.form, this.proid).then(response => {
          Message({
            message: '新增成功',
            type: 'success',
            duration: 5 * 1000
          })
          this.$emit('update:visible', false)
          loading.close()
          this.getList()
        }).catch(response => {
          loading.close()
          this.getList()
        })
      }
    },
    closePage() {
      this.$emit('update:visible', false)
      // this.getList()
    }
  }
}
</script>