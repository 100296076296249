<template>
  <div class="app-container">
    <div class="top">
      <el-button v-for="(item, index) in tabArr"
                 :key='index'
                 class="button"
                 :class="item.index === elIndex ? 'active' : ''"
                 @click="getIndex(item,index)">
        {{item.label}}
      </el-button>
    </div>
    <div class="main">
      <ywzd v-if="elIndex === 0" />
      <xtcl v-if="elIndex === 1" />
    </div>

  </div>
</template>
<script>
import ywzd from '../maDict/maSysDict/list.vue'
import xtcl from '../maDict/maSysValue/list.vue'
export default {
  name: 'Ywzd',
  components: { ywzd, xtcl },
  data () {
    return {
      activeName: 'first',
      elIndex: 0,
      tabArr: [{
        index: 0,
        label: '业务字典'
      },
      {
        index: 1,
        label: '系统常量'
      }]
    }
  },
  created () {

  },
  methods: {
    getIndex (item, index) {
      this.elIndex = index
    }
  }
}
</script>
<style lang="less" scoped>
.app-container {
  width: 100%;
  height:100%;
}
.top {
  display: flex;
  // height: 50px;
  padding: 10px 20px;
  background: #fff;
    .button {
      width: 106px;
      height: 32px;
      margin-right: 20px;
      background: #eeeeee;
      border-radius: 5px;
      text-align: center;
      font-size: 12px;
      color: #8e9fa8;
      line-height: 10px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border:none;
      &.active {
          color: #fff;
          background: #1460e0;
        }
    }
}
.main {
    height:calc(100% - 50px);
    width:100%
  }
</style>