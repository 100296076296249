import request from '@/utils/request'

export function getInitialLoading() {
  return request({
    url: '/dictGroup/getInitialLoading',
    method: 'post'
  })
}

export function getAgainLoading(data) {
  return request({
    url: '/dictGroup/getAgainLoading',
    method: 'post',
    params: data
  })
}

export function saveData(data) {
  return request({
    url: '/dictGroup/saveData',
    method: 'post',
    data
  })
}

export function editData(data) {
  return request({
    url: '/dictGroup/editData',
    method: 'post',
    data
  })
}

export function updateGroupStatus(data) {
  return request({
    url: '/dictGroup/updateStatus',
    method: 'post',
    params: data
  })
}

export function getInfo(data) {
  return request({
    url: '/dictGroup/getInfo',
    method: 'post',
    params: data
  })
}

export function getAllList() {
  return request({
    url: '/dictGroup/getAllList',
    method: 'post'
  })
}
