<template>
  <div class="app-container">
    <el-aside style="padding-right: 10px; padding-left: 15px; padding-top: 0px;float:left;width:20%;background:none;">
      <div class="block" style="width:100%;height:780px;background:#fff;">
        <el-input v-model="filterText" style="width:100%;" placeholder="关键字过滤" clearable />
        <el-tree
          id="el-tree"
          ref="tree"
          class="filter-tree"
          :data="treeData"
          node-key="id"
          :highlight-current="true"
          :props="defaultProps"
          default-expand-all
          :expand-on-click-node="false"
          :default-expanded-keys="TreeArr"
          @node-contextmenu="rightClick"
          @node-click="handleNodeClick"
        >
          <span slot-scope="{ node }">
            <span style="font-size: 15px;">
              <i v-if="node.data.state == '1'" class="el-icon-bank-card" />
              <i v-if="node.data.state != '1'" class="el-icon-close" />
              <span>{{ node.label }}</span>
            </span>
          </span>
        </el-tree>
        <div v-show="menu0Visible">
          <ul
            id="menu0"
            class="menu"
          >
            <li
              id="menu_item"
              class="el-icon-circle-plus-outline"
              @click="handleAddGroup"
            >
              <el-link :underline="false" >新增分组</el-link>
            </li>
          </ul>
        </div>
        <div v-show="menuVisible">
          <ul
            id="menu"
            class="menu"
          >
            <li
              id="menu_item"
              class="el-icon-circle-plus-outline"
              @click="handleAddStandard"
            >
              <el-link :underline="false">新增业务字典</el-link>
            </li>
            <li
              v-if="state == 1"
              id="menu_item"
              class="el-icon-edit-outline"
              @click="handleEditGroup"
            >
              <el-link :underline="false">重命名分组</el-link>
            </li>
            <li
              v-if="state == 1"
              id="menu_item"
              class="el-icon-delete"
              @click="handleDisableGroup"
            >
              <el-link :underline="false">禁用分组</el-link>
            </li>
            <li
              v-if="(state == 2)"
              id="menu_item"
              class="el-icon-delete"
              @click="handleDisableGroup"
            >
              <el-link :underline="false">启用分组</el-link>
            </li>
          </ul>
        </div>
        <div v-show="menu2Visible">
          <ul
            id="menu2"
            class="menu2"
          >
            <li
              id="menu_item"
              class="el-icon-circle-plus-outline"
              @click="handleAddStandardCode"
            >
              <el-link :underline="false">新增字典子项</el-link>
            </li>
            <li
              id="menu_item"
              class="el-icon-edit-outline"
              @click="handleEditStandard"
            >
              <el-link :underline="false">重命名业务字典</el-link>
            </li>
            <li
              v-if="state == '1'"
              id="menu_item"
              class="el-icon-delete"
              @click="handleDisableStandardCode"
            >
              <el-link :underline="false">禁用</el-link>
            </li>
            <li
              v-if="state == '2'"
              id="menu_item"
              class="el-icon-delete"
              @click="handleDisableStandardCode"
            >
              <el-link :underline="false">启用</el-link>
            </li>
          </ul>
        </div>
      </div>
    </el-aside>

    <el-aside style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%;background:none;">
      <div class="filter-container">
        <el-input v-model="query.code" placeholder="字典代码" style="width: 180px;margin-right:10px" clearable />
        <el-input v-model="query.name" placeholder="字典名称" style="width: 180px;margin-right:10px" clearable />
        <el-select v-model="query.status" placeholder="状态" style="width: 150px;margin-right:10px">
          <el-option
            v-for="item in psTypes"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="loadList()">查询</el-button>
      </div>

      <el-table :data="tableData" style="width:100%;margin:10px 0" :height="tableHeight2" border>
        <el-table-column type="index" label="序号" width="60" align="center" />
        <el-table-column prop="operation" label="操作" fixed="right" width="240" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.state==='1'" type="primary" size="mini" @click="handleEditClick(scope)">编辑字典子项</el-button>
            <el-button v-if="scope.row.state==='1'" type="danger" size="mini" @click="handleEnOrDisable(scope,'2')">禁用</el-button>
            <el-button v-if="scope.row.state==='2'" type="primary" size="small" @click="handleEnOrDisable(scope,'1')">启用</el-button>
          </template>
        </el-table-column>
        <el-table-column label="字典代码" header-align="center" align="left" width="245">
          <template slot-scope="scope">
            <span>{{ scope.row.parentCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="字典名称" header-align="center" align="left" width="245">
          <template slot-scope="scope">
            <span>{{ scope.row.parentName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="字典子项代码" header-align="center" align="left" width="245">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="字典子项名称" header-align="center" align="left" width="245">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" header-align="center" align="left" width="120">
          <template slot-scope="scope">
            <span>{{ formatterStatus(scope.row.state,'YW_BASE_STATUS') }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog :close-on-click-modal="false" title="新增分组" :visible.sync="addGroup" append-to-body  :show-close="false" width="800px">
        <addGroup :proid="parentId" v-if="addGroup" ref="addGroup" :visible.sync="addGroup" />
      </el-dialog>

      <el-dialog :close-on-click-modal="false" title="重命名分组" :visible.sync="editGroup" append-to-body :show-close="false" width="800px">
        <editGroup :proid="code" v-if="editGroup" ref="editGroup" :visible.sync="editGroup" />
      </el-dialog>

      <el-dialog :close-on-click-modal="false" title="新增业务字典" :visible.sync="addDictGroup" append-to-body :show-close="false" width="600px">
        <addDictGroup :proid="parentId" v-if="addDictGroup" ref="addDictGroup" :visible.sync="addDictGroup" />
      </el-dialog>

      <el-dialog :close-on-click-modal="false" title="重命名业务字典" :visible.sync="editDictGroup" append-to-body  :show-close="false" width="600px">
        <editDictGroup :proid="code" v-if="editDictGroup" ref="editDictGroup" :visible.sync="editDictGroup" />
      </el-dialog>

      <el-dialog :close-on-click-modal="false" title="新增字典子项" :visible.sync="addSysCode" append-to-body  :show-close="false" width="600px">
        <addDict :proid="parentId" v-if="addSysCode" ref="addDict" :visible.sync="addSysCode" />
      </el-dialog>

      <el-dialog :close-on-click-modal="false" title="编辑字典子项" :visible.sync="editSysCode" append-to-body :show-close="false" width="600px">
        <editDict :proid="code" v-if="editSysCode" ref="editDict" :visible.sync="editSysCode" />
      </el-dialog>

      <pagination v-show="total>-1" style="margin-top:0 auto;" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="loadList" />
    </el-aside>
  </div>
</template>

<script>

import { getDictListByCode, updateStatus, getPage } from '@/api/dict'
import { getInitialLoading, getAgainLoading, updateGroupStatus } from '@/api/dictGroup'
import { tableHeight2 } from '@/utils/tableHeight'
import Pagination from '@/components/Pagination'
import { Message, MessageBox } from 'element-ui'

import AddGroup from './addGroup.vue'
import EditGroup from './editGroup.vue'
import AddDictGroup from './addDictGroup.vue'
import EditDictGroup from './editDictGroup.vue'
import AddDict from './addDict.vue'
import EditDict from './editDict.vue'

export default {
  components: { Pagination, AddGroup, EditGroup, AddDictGroup, EditDictGroup, AddDict, EditDict },
  mixins: [tableHeight2],
  provide() {
    return {
      getList: this.onloadList,
      loadList: this.loadList
    }
  },
  data() {
    return {
      code: '',
      parentId: '',
      TreeArr: [],
      IdArr: [],
      psTypes: [], // 状态下拉选择
      treeCode: '', // 记录当前项目code
      filterText: '', // 树，过滤节点搜索字符串
      total: 0, // 总条数
      // 查询条件
      query: {
        page: 1,
        limit: 20,
        code: '',
        name: '',
        status: '1'
      },
      applications: [], // 应用列表
      selectNode: {
        'id': 1
      }, // 记录当前点击节点
      DATA: null, // 记录右键点击节点对象数据
      NODE: null, // 记录右键点击节点
      menu0Visible: false, // 右键菜单--应用右键菜单显示
      menuVisible: false, // 右键菜单--分组右键菜单显示
      menu2Visible: false, // 右键菜单--标准代码右键菜单显示
      addGroup: false, // 控制新增分组弹出框
      editGroup: false, // 控制编辑分组弹出框
      addSysCode: false, // 控制新增字典代码弹出框
      editSysCode: false, // 控制编辑字典代码弹出框
      addDictGroup: false, // 控制编辑字典代码弹出框
      editDictGroup: false, // 控制编辑字典代码弹出框
      formGroup: {
        name: ''
      }, // 分组
      formSysCode: {
        name: ''
      }, // 系统标准
      value: {
        name: ''
      }, // 值
      tableData: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      optionCardX: 0,
      optionCardY: 0,
      state: ''
    }
  },
  // 监听过滤节点
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  created() {
    this.getDictList('YW_BASE_STATUS')
    this.loadTree()
  },
  methods: {
    onloadList() {
      this.TreeArr = []
      this.IdArr = []
      // 重新加载表格
      this.loadTree()
    },
    // 加载数据字典
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_BASE_STATUS') {
          this.psTypes = response.data
        }
      })
    },
    // 加载树
    loadTree() {
      var that = this
      this.treeData = []
      getInitialLoading().then(response => {
        if (response.code === 20000) {
          this.treeData = response.data
          this.treeData.forEach(m => {
            this.TreeArr.push(m.id)
          })
        }
      })
    },
    // 加载列表
    loadList() {
      this.query.level = this.selectNode.level
      this.query.parentId = this.selectNode.id
      this.query.parentCode = this.selectNode.code
      getPage(this.query).then(res => {
        this.tableData = res.data.records
        this.total = parseInt(res.data.total)
      })
    },
    // 新增分组
    handleAddGroup() {
      this.parentId = this.DATA.id
      this.addGroup = true // dialog对话窗口打开
    },
    // 编辑分组
    handleEditGroup() {
      this.code = this.DATA.id
      this.editGroup = true // dialog对话窗口打开
      this.$nextTick(() => {
        this.$refs.editGroup
        this.$refs.editGroup.getDetailed()
      })
    },
    // 新增标准代码
    handleAddStandard() {
      this.parentId = this.DATA.id
      this.code = this.DATA.id
      this.addDictGroup = true // dialog对话窗口打开
    },
    // 编辑标准代码
    handleEditStandard() {
      this.code = this.DATA.id
      this.editDictGroup = true // dialog对话窗口打开
      this.$nextTick(() => {
        this.$refs.editDictGroup
        this.$refs.editDictGroup.getDetailed()
      })
    },
    // 新增标准代码
    handleAddStandardCode() {
      this.parentId = this.DATA.id
      this.code = this.DATA.id
      this.addSysCode = true // dialog对话窗口打开
    },
    // 编辑按钮
    handleEditClick(scope) {
      this.code = scope.row.id // 将数据传入dialog页面
      this.editSysCode = true // dialog对话窗口打开
      this.$nextTick(() => {
        this.$refs.editDict
        this.$refs.editDict.getDetailed()
      })
    },
    // 右键点击
    rightClick(MouseEvent, object, Node, element) { // 鼠标右击触发事件 鼠标点击事件 对象（数据） 节点 元素
      this.DATA = object
      this.state = object.state
      this.NODE = Node
      if (this.NODE.level === 1) { // 应用 -- 可以创建分组
        this.menuVisible = false // 弹出框1隐藏
        this.menu2Visible = false // 弹出框2隐藏
        // // 显示弹窗0
        this.menu0Visible = true // 显示模态窗口，跳出自定义菜单栏
        var menu0 = document.querySelector('#menu0')
        document.addEventListener('click', this.foo) // 给整个document添加监听鼠标事件，点击任何位置执行foo方法
        menu0.style.display = 'block'
        menu0.style.left = MouseEvent.clientX + 30 + 'px' // 右键弹出框横向位置
        menu0.style.top = MouseEvent.clientY - 10 + 'px' // 右键弹出框纵向位置
      } else if (this.NODE.level === 2) { // 分组
        this.menu0Visible = false // 弹出框2隐藏
        this.menu2Visible = false // 弹出框2隐藏
        // 显示弹窗1
        this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
        var menu1 = document.querySelector('#menu')
        document.addEventListener('click', this.foo) // 给整个document添加监听鼠标事件，点击任何位置执行foo方法
        menu1.style.display = 'block'
        menu1.style.left = MouseEvent.clientX + 30 + 'px' // 右键弹出框横向位置
        menu1.style.top = MouseEvent.clientY - 10 + 'px' // 右键弹出框纵向位置
      } else if (this.NODE.level === 3) { // 标准代码
        this.menu0Visible = false // 弹出框1隐藏
        this.menuVisible = false // 弹出框1隐藏
        // 显示弹窗2
        this.menu2Visible = true // 显示模态窗口，跳出自定义菜单栏
        var menu2 = document.querySelector('#menu2')
        document.addEventListener('click', this.foo) // 给整个document添加监听鼠标事件，点击任何位置执行foo方法
        menu2.style.display = 'block'
        menu2.style.left = MouseEvent.clientX + 30 + 'px' // 右键弹出框横向位置
        menu2.style.top = MouseEvent.clientY - 10 + 'px' // 右键弹出框纵向位置
      }
    },
    // 取消鼠标监听事件 菜单栏
    foo() {
      this.menuVisible = false
      this.menu2Visible = false
      this.menu0Visible = false
      document.removeEventListener('click', this.foo) // 要及时关掉监听，不关掉的是一个坑，不信你试试，虽然前台显示的时候没有啥毛病，加一个alert你就知道了
    },
    // 格式化状态
    formatterStatus(value, flag) {
      if (flag === 'YW_BASE_STATUS') {
        for (var i in this.psTypes) {
          if (this.psTypes[i].code === value) {
            return this.psTypes[i].name
          }
        }
      }
    },
    // 过滤节点，展示所有符合条件的树节点
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    // 点击节点，1：展开/关闭下级节点；2：查询右面列表数据
    handleNodeClick(data, node, e) {

      var dataA = data
      // 关闭右键弹出框
      this.closeRightClick()
      this.state = data.state
      // 记录当前选择节点
      this.selectNode = data
      this.selectNode.level = node.level

      if(node.level < 3){
        if (!this.IdArr.includes(dataA.id)) {
          // 树
          getAgainLoading({ parentId: dataA.id }).then(response => {
            if (!dataA.children) {
              this.$set(dataA, 'children', [])
              response.data.forEach(newChild => {
                dataA.children.push(newChild)
              })
              this.IdArr.push(dataA.id)
              this.dataId = dataA.id
            } else {
              return
            }

            this.listLoading = false
          }).catch(response => {
            this.listLoading = false
          })
        }
      }
      // 根据选择节点获取值
      this.loadList()
    },
    // 禁用分组
    handleDisableGroup() {
      var flagName = '启用'
      var flag = '1'
      if (this.state === '1') {
        flagName = '禁用'
        flag = '2'
      }

      MessageBox.confirm('是否确认' + flagName + '该节点?', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '' + flagName + '中......',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        updateGroupStatus({ id: this.DATA.id, status: '2' }).then(response => {
          Message({
            message: '' + flagName + '成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          // 加载树
          this.onloadList()
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {
        console.log('' + flagName + '取消')
      })
    },
    // 禁用标准代码
    handleDisableStandardCode() {
      var flagName = '启用'
      var flag = '1'
      if (this.state === '1') {
        flagName = '禁用'
        flag = '2'
      }

      MessageBox.confirm('是否确认' + flagName + '该节点?', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '' + flagName + '中......',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        updateStatus({ id: this.DATA.id, status: flag }).then(response => {
          Message({
            message: '' + flagName + '成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          // 加载树
          this.onloadList()
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {
        console.log('' + flagName + '取消')
      })
    },
    // 启用或禁用值
    handleEnOrDisable(scope, flag) {
      var flagName = '禁用'
      if (flag === '1') {
        flagName = '启用'
      }
      MessageBox.confirm('是否确认' + flagName + '该值?', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: flagName + '中......',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        updateStatus({ id: scope.row.id, status: flag }).then(response => {
          Message({
            message: flagName + '成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载列表
          this.loadList()
        })
      }).catch(() => {
      })
    },
    // 关闭右键弹窗
    closeRightClick() {
      this.menu0Visible = false
      this.menuVisible = false // 弹出框1隐藏
      this.menu2Visible = false // 弹出框2隐藏
      this.formSysCode = {
        name: ''
      } // 初始化系统标准新增/编辑
    }
  }
}
</script>

<style scoped>
.app-container{
    height:calc(100% - 20px);
    width:calc(100% - 40px);
    padding:10px 0;
}
.left{
  float:left;
  width:20%;
}
.right{
  float:right;
  width:75%
}
.margin{
  margin:10px
}
.btnRight{
  margin-right:10px
}
.menu {
  line-height: 32px;
  width: 120px;
  position: fixed;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "黑体";
  color: #474a4d;
  font-weight: 100;
  background-color: white;
  list-style: none;
  padding-left: 10px;
}

.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: center;
}
.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.menu2 {
  line-height: 32px;
  width: 130px;
  position: fixed;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "黑体";
  color: #474a4d;
  font-weight: 100;
  background-color: white;
  list-style: none;
  padding-left: 10px;
}
.contextmenu__item:hover {
  cursor: pointer;
  background: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}

</style>
